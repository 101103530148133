import React from 'react'
import './css/footer.css'

export default function Footer() {
  return (
    <div className='footer-copyright'>
        <h4>Melodrift</h4>
        <p>Copyright 2024</p>
    </div> 
  )
}
